import * as React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"

const FeaturedWorks = () => {
  const data = useStaticQuery(graphql`
    query featuredWorks {
      allWpFeaturedWork {
        nodes {
          id
          acfFields {
            featuredWorkImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, formats: WEBP, quality: 10)
                }
              }
            }
            featuredWorkCategory
            featuredWorkLink
            featuredWorkTitle
          }
        }
      }
    }
  `);

  const featuredWorks = data.allWpFeaturedWork.nodes;

  return (
    <div className="row justify-content-evenly py-5">
      { featuredWorks.map(node => (
        <div className="col-12 col-md-6 col-lg-4 col-xl-3 pb-4" key={node.id}>
          <div className="card h-100">
            <GatsbyImage
              className="card-img-top shadow-sm p-0"
              image={node.acfFields.featuredWorkImage.localFile.childImageSharp.gatsbyImageData} 
              alt={ node.acfFields.featuredWorkTitle + " Image"} 
            />
            <div className="card-body shadow">
              <p className="card-title">{node.acfFields.featuredWorkTitle}</p>
              <p className="card-text fst-italic">{node.acfFields.featuredWorkCategory}</p>
              <a href={node.acfFields.featuredWorkLink} target="_blank" rel="nooperner noreferrer">Visit Site</a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default FeaturedWorks;