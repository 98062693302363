import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby"; //highlight-line
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";
import FeaturedWorks from "../components/featured-works";
import ContactForm from "../components/contact-form";

import parse from 'html-react-parser';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query FrontPage {
      allWpPage(filter: {uri: {eq: "/"}}) {
        nodes {
          frontPage {
            bannerCatchPhrase
            bannerImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, formats: WEBP, quality: 10)
                }
              }
            }
            companyDescription
            gatsbyjs
            wordpress
            wordpressAndGatsbyjs
          }
        }
      }
    }
  `);

  const frontPage = data.allWpPage.nodes[0].frontPage;

  const bannerImage = frontPage.bannerImage.localFile.childImageSharp.gatsbyImageData;

  return (
    <Layout>
      <Seo title="Home"/>
      <section className="container index-banner">
        <div className="row d-flex justify-content-center">
          <div className="col-12 col-lg-6">
            <GatsbyImage image={bannerImage} alt="Banner Image"></GatsbyImage>
          </div>
          <div className="col-12 col-lg-6 text-center text-lg-start">
            <h2 className="display-2 fw-bold">Your <span className="custom-text-color">Company</span></h2>
            <h2 className="display-2 fw-bold">Your <span className="custom-text-color-secondary">Website</span></h2>
            <p className="fs-6"> {frontPage.bannerCatchPhrase} </p>
            <Link to="/contact-us" className="btn custom-btn-primary me-5">Contact Us</Link>
            <Link to="/services" className="btn custom-btn-secondary">Learn More</Link>
          </div>
        </div>
      </section>
      <section className="container index-about-us py-5">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-8">
            <h3 className="display-6 fw-bold">A Web Design and Development Company</h3>
            <p className="fs-5">{parse(frontPage.companyDescription)}</p>
          </div>
        </div>
      </section>
      <section className="container-fluid d-flex justify-content-evenly index-technology shadow">
        <StaticImage
          src="../images/gatsby-original.svg"
          alt="Gatsby Icon"
          placeholder="blurred"
        />
        <StaticImage
          src="../images/react-original.svg"
          alt="React Icon"
          placeholder="blurred"
        />
        <StaticImage
          src="../images/wordpress-original.svg"
          alt="WordPress Icon"
          placeholder="blurred"
        />
        <StaticImage
          src="../images/netlify-original.svg"
          alt="Netlify Icon"
          placeholder="blurred"
        />
      </section>
      <section className="container index-services">
        <h3 className="display-5 fw-bold text-center">Services</h3>
        <hr className="horizontal-line"/>
        <div className="row justify-content-evenly py-5">
          <div className="col-12 col-sm-8 col-md-6 col-xl-4 pb-3 pb-md-4 pb-xl-0">
            <div className="card h-100 shadow-sm">
              <div className="card-body">
                <div className="card-icon text-center">
                  <i className="bi bi-window display-1 custom-text-color-terniary"></i>
                </div>
                <h4 className="card-title text-center">Web Design</h4>
                <p className="card-text fs-5">{ frontPage.gatsbyjs }</p>
                <Link to="/services" className="btn custom-btn-secondary">Learn More</Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-8 col-md-6 col-xl-4 pb-3 pb-md-4 pb-xl-0">
            <div className="card h-100 shadow-sm">
              <div className="card-body">
                <div className="card-icon text-center">
                  <i className="bi bi-code-square display-1 custom-text-color"></i>
                </div>
                <h4 className="card-title text-center">Web Development</h4>
                <p className="card-text fs-5">{ frontPage.wordpress }</p>
                <Link to="/services" className="btn custom-btn-secondary">Learn More</Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-8 col-md-6 col-xl-4">
            <div className="card h-100 shadow-sm">
              <div className="card-body">
                <div className="card-icon text-center">
                  <i className="bi bi-type-h1 display-1 custom-text-color-secondary"></i>
                </div>
                <h4 className="card-title text-center">SEO</h4>
                <p className="card-text fs-5">{ frontPage.wordpressAndGatsbyjs }</p>
                <Link to="/services" className="btn custom-btn-secondary">Learn More</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container index-featured-work">
        <h3 className="display-5 fw-bold text-center">Featured Works</h3>
        <hr className="horizontal-line"/>
        <FeaturedWorks></FeaturedWorks>
      </section>
      <section className="container index-contact-us">
        <h3 className="display-5 fw-bold text-center">Contact Us</h3>
        <hr className="horizontal-line"/>
        <ContactForm></ContactForm>
      </section>
    </Layout>
  );
}

export default IndexPage;