import * as React from 'react';

const ContactForm = () => {
  return (
  <section id="contact-form" className="container-xl contact-form">
    <form name="contact v3" method="POST" data-netlify="true" netlify-honeypot="bot-field">
      <input type="hidden" name="form-name" value="contact v3"/>
      <input type="hidden" name="bot-field"/>
      
      <div className="row justify-content-center gy-3">

        <div className="col-12 col-md-7 col-lg-12">
          <label htmlFor="first-name">First Name: </label>
          <input type="text" id="first-name" name="first-name" className="form-control" aria-describedby="first-name" required/>
        </div>

        <div className="col-12 col-md-7 col-lg-12">
          <label htmlFor="last-name">Last Name: </label>
          <input type="text" id="last-name" name="last-name" className="form-control" aria-describedby="last-name" required/>
        </div>

        <div className="col-12 col-md-7 col-lg-12">
          <label htmlFor="email">Email: </label>
          <input type="email" id="email" name="email" className="form-control" aria-describedby="email" required/>
        </div>

        <div className="col-12 col-md-7 col-lg-12">
          <label htmlFor="inquiry">Inquiry: </label>
          <textarea id="inquiry" className="form-control" rows="10"></textarea>
        </div>

        <div className="col-12 col-md-7 col-lg-12">
          <button className="btn custom-btn-primary" type="submit">Get in Touch!</button>
        </div>
        
      </div>
    </form>
  </section>
  );
}

export default ContactForm;